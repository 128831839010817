import React, { useState, useEffect, createContext } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import isOnline from 'is-online';

import isBrowser from '../utils/isBrowser';
import AppContext from '@contexts/AppContext';
import { isLoggedIn, validateAuthenticatedUser } from '../services/auth';
import { Transition, RestrictionsMessage } from '@components';

const navigateToLogin = (location) => {
  localStorage.setItem('REDIRECT_URL', location.pathname);
  navigate(`/login/`);
}

const Layout = ({ children, location }) => {
  const isOffline = process.env.GATSBY_OFFLINE === 'true';
  const noLogin = process.env.GATSBY_NO_LOGIN === 'true';
  const [isReady, setIsReady] = useState(false);
  // const onProjectedRoute = location.pathname.match(/^\/collection\//) || location.pathname.match(/^\/collection-highlights/);
  const onProjectedRoute = location.pathname !== '/' && !location.pathname.match(/^\/login\//);

  useEffect(() => {
  /**
   * If user is not logged in
   */
  if (noLogin && !isReady) {
    setIsReady(true);
   } else {
    if (onProjectedRoute && !isReady) {
      if (isBrowser() && !isLoggedIn()) {
        navigateToLogin(location);

      /**
       * Validate logged in user if on the web version
       */
      } else if (isLoggedIn()) {
        // On offline we can't validate the authenticated user
        // The user must sign in while they have internet and 
        // afterwards they will be able to access the site without logging in
        (async () => {
          const isCurrentlyOnline = await isOnline();

          if (isOffline && !isCurrentlyOnline) {
            setIsReady(true);
          } else {
            validateAuthenticatedUser(() => {
              // Validation succeeded
              setIsReady(true);
            }, () => {
              // Validation failed
              navigateToLogin(location);
              setIsReady(true);
            })
          }
        })();
      }
    }
  }
  }, [location.pathname]);

    /**
    * Wait until user is validated before rendering
    */
   if (!isReady && onProjectedRoute) {
    return null;
  }

  return (
    <Transition location={location}>
      <React.Fragment>
        <Helmet>
          <meta http-equiv="Content-Type" content="text/html;charset=utf-8" />
          <title>ECCO AW20</title>
          <link rel="stylesheet" href="https://use.typekit.net/byf7ppr.css" />
          <meta name="robots" content="noindex, nofollow" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
        <RestrictionsMessage />
        <AppContext.Provider value={{
          appIsReady: isReady,
          setAppIsReady: setIsReady
        }}>
          {children}
        </AppContext.Provider>
      </React.Fragment>
    </Transition>
  )
}

export default Layout
