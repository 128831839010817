import React, { useState } from 'react';
import styled from 'styled-components';

import { cubicBezier } from '../assets/styles/constants'

const ProgressMenu = ({ items, selected, itemType, onItemClick, presentationMode }) => {
  // Progress menu can be toggled in presentation mode
  const [visible, setVisible] = useState(!presentationMode);
  const itemsToLoop = []
  for (let index = 0; index < items; index++) {
    itemsToLoop.push(index)
  }

  const Item = itemType === 'number' ? ProgressMenuNumber : ProgressMenuDot;

  if (itemsToLoop.length === 1) {
    return null;
  }

  return (
    <ProgressMenuWrapper presentationMode={presentationMode} isVisible={visible}>
      <ProgressMenuInner isVisible={visible}>
        {itemsToLoop.map((item, index) => (
          <Item
            onClick={typeof onItemClick === 'function' ? () => onItemClick(index + 1) : null}
            type={itemType}
            selected={selected === index + 1}
            key={`ProgressMenuItem-${index}`}
          >
            {itemType === 'number' ? index + 1 : null}
          </Item>
        ))}
      </ProgressMenuInner>
      {presentationMode && (
        <InvisibleToggleButton onClick={() => setVisible(!visible)}>
          {selected}
        </InvisibleToggleButton>
      )}
    </ProgressMenuWrapper>
  )
}

ProgressMenu.defaultProps = {
  presentationMode: false,
}

export default ProgressMenu

const ProgressMenuWrapper = styled.div`
  position: fixed;
  display: flex;
  right: 50px;
  z-index: 900;
  top: 0;
  bottom: 0;
  width: ${props => props.presentationMode ? '200px' : 'auto'};
}
`

const ProgressMenuInner = styled.div`
  display: ${props => props.isVisible ? 'flex' : 'none' };
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem 0;
  max-height: 100%;
`

const ProgressMenuDot = styled.div`
  float: right;
  clear: both;
  background: ${props => (props.selected ? '#fff' : 'transparent')};
  height: 10px;
  width: 10px;
  border: 1px solid #fff;
  border-radius: 100%;
  opacity: ${props => (props.selected ? '1' : '0.3')};
  transition: all 0.5s ${cubicBezier};
  user-select: none;
  margin: 5px;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }  
`

const ProgressMenuNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.selected ? '#fff' : 'transparent')};
  color: ${props => (props.selected ? '#000' : '')};
  font-weight: ${props => (props.selected ? 'bold' : '')};
  height: 22px;
  width: 22px;
  line-height: 1;
  font-size: 12px;
  border: 1px solid #fff;
  border-radius: 100%;
  opacity: ${props => (props.selected ? '0.5' : '0.3')};
  transition: all 0.5s ${cubicBezier};
  margin: 5px;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

const InvisibleToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  right: 0;
  opacity: 0;


  &:hover {
    opacity: 1;
    cursor: pointer;
    background-color: #FFF;
    color: #000;
  }
`