import React from 'react';
import classnames from 'classnames';
import css from './styles.module.css';

const CPLogo = ({ className, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 204 231"
    className={classnames(css.CPlogo, className)}
    {...rest}
  >
    <path d="M153.5 68.6H63.9c.5-25.5 2.9-33.2 11.2-41.6 7.4-7.3 17.4-11.4 27.9-11.3 19.7 0 34.2 12.5 38.6 33 .3 1.2 1.3 2 2.5 2h11.2c.4 0 .9-.1 1.4-.3 1.3-.6 2.1-2 1.8-3.6C153.2 18.1 132.1.1 103 .1 87.6 0 72.8 6.1 62 17 50.1 28.7 47.3 37.5 46.8 68.7 19.3 70.2.8 88.2.7 116.2c0 .3 0 .6.1.9.4 28.6 20.6 46.8 49.7 46.8h42.9v63.8c0 1.4 1.2 2.6 2.6 2.6h11.9c1.4 0 2.6-1.2 2.6-2.6v-63.8h42.9c29.5 0 49.9-18.5 49.9-47.6 0-29.3-20.2-47.7-49.8-47.7zM46.7 84.5c.1 40.2 2.1 49.7 15.3 63 .2.2.4.4.7.6H51.9c-21.6 0-34.2-11-34.2-31.9 0-19.2 10.5-30 29-31.7zm46.8 62.9c-6.9-1.7-13.2-5.2-18.3-10.2-9.5-9.4-11.2-18.2-11.3-52.9h29.7v63.1zm65.3-30.9c.3-1.6-.9-3.1-2.5-3.1h-10.8c-1.7 0-3.2 1.2-3.5 2.8-3.7 17.2-15.4 28.8-31.3 31.6V84.3h41.6c21.6 0 34.1 11 34.1 31.9s-12.5 31.9-34.1 31.9h-9.1c7.7-8 13.2-18.8 15.6-31.6z" />
  </svg>
);

export default CPLogo;
