/**
 * CollectionPremiereHero
*/

import React from 'react';
import { graphql } from 'gatsby';
import CPLogo from '@assets/svgs/CP-logo';
import { Video } from '@components';
import css from './CollectionPremiereHero.module.css';

const CollectionPremiereHero = ({ title, subtitle, videoPath, isActive, ...rest }) => {
  return (
    <div className="section" {...rest}>
      <div className={css.inner}>
        {videoPath && (
          <div className={css.videoBackground}>
            <Video
              autoPlay 
              muted
              loop
              preload="auto"
              path={videoPath}
              inView={isActive}
            />
          </div>
        )}
        <div className={css.content}>
          <CPLogo className={css.logo} />
          {title && <h1 className={css.title} dangerouslySetInnerHTML={{ __html: title }} />}
          {subtitle && <h2 className={css.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />}
        </div>
      </div>
    </div>
  );
};

export default CollectionPremiereHero;


export const sectionsHero = graphql`
  fragment SectionHero on WordPressAcf_hero {
    ... on WordPressAcf_hero {
      title
      subtitle
      video_path
    }
  }
`
