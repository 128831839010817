/**
 * Restrictions Message
 * 
 * Prompts the user to accept terms before continuing when signed in
 */

import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import css from './RestrictionsMessage.module.scss';
import Button from '../Button';
import { StaticQuery, graphql } from 'gatsby';
import isBrowser from '../../utils/isBrowser';

const HAS_SEEN_STORAGE_KEY = 'has_accepted_restrictions';

const RestrictionsMessage = ({ title, sub, text, buttonLabel }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const hasAccepted = !!JSON.parse(window.localStorage.getItem(HAS_SEEN_STORAGE_KEY));

    if (isBrowser() && !hasAccepted) {
      setVisible(true);
    }
  }, []);

  const onAccept = () => {
    if (isBrowser()) {
      window.localStorage.setItem(HAS_SEEN_STORAGE_KEY, true);
    }
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <div className={css.root}>
      <div className={css.content}>
        <h1 className={css.content__title} dangerouslySetInnerHTML={{ __html: title }} />
        <h2 className={css.content__sub} dangerouslySetInnerHTML={{ __html: sub }} />
        <div className={css.content__text} dangerouslySetInnerHTML={{ __html: text }} />
        <Button onClick={onAccept} theme="dark" type="button" className={css.content__button}>
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

RestrictionsMessage.propTypes = {
  title: PropTypes.node,
  sub: PropTypes.node,
  text: PropTypes.node,
  buttonLabel: PropTypes.node,
};

export default () => (
  <StaticQuery  
    query={graphql`
    query RestrictionsQuery {
      allWordpressAcfOptions {
        edges {
          node {
            id
            options {
              restrictions_modal_button_label
              restrictions_modal_sub
              restrictions_modal_text
              restrictions_modal_title
            }
          }
        }
      }
    }
  `}
  render={data => {
    const options = get(data, 'allWordpressAcfOptions.edges.[0].node.options');
    return (
      <RestrictionsMessage
        title={get(options, 'restrictions_modal_title')}
        sub={get(options, 'restrictions_modal_sub')}
        text={get(options, 'restrictions_modal_text')}
        buttonLabel={get(options, 'restrictions_modal_button_label')}
      />
    )
  }}
  />
);
