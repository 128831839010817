import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { cubicBezier, MQ } from '../../assets/styles/constants';

const AccordionContent = posed.div({
  closed: { height: 0 },
  open: { height: 'auto' }
});

class ProductItem extends React.Component {
  render() {
    const {
      selectedSection,
      productData,
      currentPageId,
      handleSectionItemClick,
      handleProductItemClick,
    } = this.props

    const openedProductItemMenu = this.props.productItemToOpen === this.props.productData.id

    return (
      <ProductItemWrapper
        key={`product-${productData.id}`}
        currentPageId={currentPageId}
        productId={productData.id}
      >
        <ProductTitle
          onClick={() =>
            handleProductItemClick({
              productId: productData.id,
            })
          }
          openedProductItemMenu={openedProductItemMenu}
          className="product-item-title"
          dangerouslySetInnerHTML={{ __html: productData.title }}
        />
        <ProductSections openedProductItemMenu={openedProductItemMenu} pose={openedProductItemMenu ? 'open' : 'closed'}>
          {productData.acf.sections_page &&
            productData.acf.sections_page
            .map((section, index) => {
              return (
                <Section
                  className="product-item-section"
                  key={`section-${section.id}`}
                  index={index}
                  selectedSection={selectedSection}
                  currentPageId={currentPageId}
                  productId={productData.id}
                  onClick={() =>
                    handleSectionItemClick({
                      productId: productData.id,
                      clickedSectionId: index + 1,
                      url: `/collection/${productData.parent_element.slug}/${productData.slug}`,
                    })
                  }
                >
                  <span dangerouslySetInnerHTML={{ __html: section.section_title }} />
                </Section>
              )
            })}
        </ProductSections>
      </ProductItemWrapper>
    )
  }
}

export default ProductItem

const ProductItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;
`

const ProductTitle = styled.div`
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  padding-right: 1rem;
  position: relative;
  cursor: pointer;
  user-select: none;

  &:before,
  &:after {
    content: '';
    background: ${props => (props.openedProductItemMenu ? '#ffffff' : '#5e5e5e')};
    width: 10px;
    height: 3px;
    display: block;
    position: absolute;
    top: 8px;
    transition: all 0.5s ${cubicBezier};
  }

  &:hover {
    font-weight: 700;
  }

  &:before {
    right: 5px;
    transform: ${props => (props.openedProductItemMenu ? 'rotate(-45deg)' : 'rotate(-135deg)')};
  }
  &:after {
    right: 0;
    transform: ${props => (props.openedProductItemMenu ? 'rotate(45deg)' : 'rotate(135deg)')};
  }
`

const ProductSections = styled(AccordionContent)`
  overflow: hidden;
  // transition: all 0.5s ${cubicBezier};
  //  max-height: ${props => (props.openedProductItemMenu ? '1500px' : '0px')};
`

const Section = styled.div`
  padding: 20px 0;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 14px;
  position: relative;
  transition: all 0.5s ${cubicBezier};
  cursor: pointer;
  user-select: none;
  opacity: ${props =>
    props.currentPageId === props.productId && props.selectedSection === props.index + 1
      ? '1'
      : '0.3'};

  @media screen and (${MQ.lg}) {
    &:hover {
      opacity: 1;
      font-weight: 700;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate3d(0, -50%, 0);
    width: 14px;
    height: 14px;
    border-radius: 100%;
    transition: all 0.5s ${cubicBezier};
    background: ${props =>
      props.currentPageId === props.productId && props.selectedSection === props.index + 1
        ? 'rgba(255, 255, 255, 1)'
        : 'rgba(255, 255, 255, 0)'};
    border: 1px solid #fff;
  }
`
