// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-product-index-js": () => import("./../src/templates/CategoryProduct/index.js" /* webpackChunkName: "component---src-templates-category-product-index-js" */),
  "component---src-templates-category-frontpage-index-js": () => import("./../src/templates/CategoryFrontpage/index.js" /* webpackChunkName: "component---src-templates-category-frontpage-index-js" */),
  "component---src-templates-landingpage-index-js": () => import("./../src/templates/Landingpage/index.js" /* webpackChunkName: "component---src-templates-landingpage-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-collection-js": () => import("./../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-download-js": () => import("./../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-video-status-js": () => import("./../src/pages/video-status.js" /* webpackChunkName: "component---src-pages-video-status-js" */)
}

